import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"

import Hero from '../../images/fall-winter-2022/presidents-message/hero.jpg';
import framSignature from "../../images/fall-winter-2022/presidents-message/fram-signature.svg";

import socialBanner from "../../images/fall-winter-2022/social-banners/presidents-message.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'At the start of the fall semester, I challenged the Titan community to consider three questions: What are we doing to help students navigate our campus?';
    var slug = 'presidents-message';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white',
      height: '80vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p><span className="intro-text">At the start of the fall semester</span>, I challenged the Titan community to consider three questions: What are we doing to help students navigate our campus?What are we doing to assure that our students thrive and succeed in their academic journey? What are we doing to make our students feel welcome, included and part of the Titan family?</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>I first heard these questions asked at a recent higher education summit with U.S. Secretary of Education Miguel Cardona — a meeting where Cal State Fullerton, unlike any other institution, was publicly highlighted twice by Deputy Secretary of Education Cindy Marten in recognition of all we are doing.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Research shows that students, as well as faculty and staff, are more invested and do better when a campus centers itself around answering these simple yet impactful questions. And when students do better, society benefits; families change; communities are lifted; and a region, state and nation are positively impacted.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>It’s true: We are doing amazing things to empower students. This past year, we increased graduation rates for first-time freshmen and graduated one of the largest classes in Titan history. This past year, more historically underrepresented students earned their bachelor’s and master’s degrees, and more women earned their bachelor’s degrees and doctorates than any other year in our history.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>But behind these successes are individual and inspiring stories of students, faculty, staff and alumni — stories like the ones you’ll read in Titan magazine.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>In this issue, you’ll read about Zack Collie, a quadriplegic student who discovered his calling at CSUF as a future counselor for other survivors of traumatic events. He graduates this winter with a master’s degree in counseling. You’ll read about faculty and student researchers building a new observatory that will push gravitational-wave astronomy toward the edge of the observable universe. And you’ll read about arts alumni behind the scenes of the exquisite Cirque du Soleil, biology alumni working to protect endangered species like the California condor, and kinesiology alumni coaching elite basketball teams like the 2022 NBA Champions.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>These stories are just a few examples of how Cal State Fullerton is answering the three questions above. My hope is that the questions continue to drive the work of our university in the coming year and beyond — inspiring us to become wayfinders for our students, facilitators of exploration and discovery, and builders of a community where all have the opportunity to achieve their potential and contribute to the world.</p>
          </ScrollAnimation>
 
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Framroze M. Virjee, JD<br />
            President<br />
            California State University, Fullerton
          </p>
          </ScrollAnimation>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 